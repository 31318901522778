import { Link } from "react-router-dom";
import "./footer.css";

export const Footer = (props)=>{
    return (
        <div className="debug footer">
            <div className="navi">
                <div className="item">
                    <Link to="service.doc.html">服务条款</Link>
                </div>

                <div  className="item">
                    <Link to="privacy.doc.html">隐私声明</Link>
                </div>

                <div  className="item">
                    <Link to="community.doc.html">社区规范</Link>
                </div>

                <div className="item">
                    <Link to="rule.doc.html">发帖准则</Link>
                </div>
            </div>
            <div className="cert">
                <div style={{marginRight: '12px'}}>
                <a href="http://beian.miit.gov.cn/" target="_blank">京ICP备15013334号-2</a>
                </div>
                <div>
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102003126" target="_blank">京公网安备 11010102003126</a>
                </div>
            </div>
        </div>
    )
}
